import DeleteIcon from '@mui/icons-material/Delete'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Badge, IconButton } from '@mui/material'
import terms from 'assets/localize/terms'
import { useAppDispatch, useAppSelector } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  resetFileData,
} from 'reducers/simulations/slices'
import {
  deleteSimulations,
  exportSimulations,
  getCommentSeverityList,
  getCommentStatusList,
  getSimulationsList, getSimulationsUsers, getVersions,
} from 'reducers/simulations/thunks'
import { Simulation } from 'reducers/types'
import { sanitizeFilters } from 'features/history/services/filter'
import { FilterOperators, FilterWithId } from 'types/filters'
import { savefile } from 'utils'

import { Header } from 'components'
import SimpleButton, { ButtonStyle } from 'components/button/SimpleButton'
import Filters, { FilterSignal } from 'features/history/components/filters/Filters'
import SimpleModal from 'components/modals/simpleModal/SimpleModal'
import './style.scss'
import { BasicTable } from 'features/history'
import { resetSimulationsSignal } from 'features/history/components/table/table'

export default function History() {
  const dispatch = useAppDispatch()
  const {
    fileData, filters, simulationsList,
  } = useAppSelector(state => state.simulations)
  const [openModal, setOpenModal] = useState(false)
  const [order, setOrder] = useState<string>('-')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [selectedSimulations, setSelectedSimulations] = useState<string[]>([])
  const [pageSize, setPageSize] = useState(50)
  const [page, setPage] = useState(0)
  const [allSelected, setAllSelected] = useState(false)
  const [simFetched, setSimFetched] = useState(false)

  useEffect(() => {
    dispatch(getCommentStatusList())
    dispatch(getCommentSeverityList())
    dispatch(getVersions())
    dispatch(getSimulationsUsers())
  }, [])

  useEffect(() => {
    if (openModal) return

    dispatch(getSimulationsList(
      {
        ordering: order.concat(orderBy),
        page_size: pageSize,
        page: page + 1,
        filters: sanitizeFilters(filters as FilterWithId[]),
      },
    ))
    // todo remove boolean and fix useeffects
    setSimFetched(true)
  }, [order, openModal, pageSize, page])

  useEffect(() => {
    if (!simFetched) return

    if (filters.some(filter => (filter.value === '')
      && (filter.operator !== FilterOperators.null && filter.operator !== FilterOperators.notNull))) return

    dispatch(getSimulationsList(
      {
        ordering: order.concat(orderBy),
        page_size: pageSize,
        page: 1,
        filters: sanitizeFilters(filters as FilterWithId[]),
      },
    ))
  }, [filters])

  useEffect(() => {
    if (allSelected) {
      setSelectedSimulations([])
      setAllSelected(false)
    }
  }, [page])

  const handleSelectedSimulations = (ids: string[]) => {
    if (allSelected) {
      setAllSelected(false)
    }
    setSelectedSimulations(ids)
  }

  useEffect(() => {
    if (fileData) {
      savefile(fileData, moment().format('DD/MM/YYYY'))
    }
    dispatch(resetFileData())
  }, [fileData])

  const handleExport = () => {
    let exportFilters = []
    if (allSelected) {
      exportFilters = filters
    } else {
      exportFilters = [{
        operator: FilterOperators.in,
        onField: 'id',
        value: selectedSimulations,
      }]
    }
    dispatch(exportSimulations(exportFilters))
  }

  const handleClickSimulation = (simulation: Simulation) => {
    window.open(`${window.location.origin}/logs/${simulation.id}?mode=history`)
  }

  const handleConfirmModal = () => {
    let deleteFilters = []

    if (allSelected) {
      deleteFilters = filters
    } else {
      deleteFilters = [{
        operator: FilterOperators.in,
        onField: 'id',
        value: selectedSimulations,
      }]
    }
    dispatch(deleteSimulations({
      filters: deleteFilters,
    })).then(res => {
      if (res.type.includes('fulfilled')) {
        setOpenModal(false)
        setSelectedSimulations([])
        setAllSelected(false)
      }
    })
  }

  const handleDeleteSimulations = () => {
    setOpenModal(true)
  }

  const getSelectAllSimulations = () => {
    if (selectedSimulations.length > 0) {
      const onClick = (allSelected ? () => {
        resetSimulationsSignal.value = true
        setSelectedSimulations([])
        setAllSelected(false)
      } : () => setAllSelected(true))

      return (
        <button
          type="button"
          className="simulation-all"
          onClick={onClick}
        >
          {allSelected ? terms.History.unselectAll : terms.History.selectAll(simulationsList.count)}
        </button>
      )
    }
    return null
  }

  return (
    <div className="history">
      <Header />
      {FilterSignal.value && (<Filters />)}

      <div className="wrapper d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex" style={{ alignItems: 'baseline' }}>
            <div style={{ fontSize: 24 }}><strong>Choisissez une simulation</strong></div>
            <div style={{ marginLeft: 10 }}>
              {allSelected ? simulationsList.count : selectedSimulations.length}
              {' '}
              simulation(s) sélectionnée(s)
              sur
              {' '}
              {simulationsList.count}
            </div>

            {getSelectAllSimulations()}
          </div>
          <div className="buttons">
            <IconButton
              aria-label="delete-simulations"
              disabled={!selectedSimulations.length}
              onClick={handleDeleteSimulations}
            >
              <DeleteIcon />
            </IconButton>
            <Badge badgeContent={filters.length}>
              <SimpleButton
                text="Filtres"
                style={ButtonStyle.primary}
                startIcon={<FilterAltIcon />}
                onClick={() => { FilterSignal.value = true }}
              />
            </Badge>
            <SimpleButton
              text="Exporter vers Excel"
              style={ButtonStyle.primary}
              startIcon={<UploadFileIcon />}
              onClick={() => handleExport()}
              disabled={selectedSimulations.length === 0}
            />

          </div>
        </div>
        <BasicTable
          setOrder={setOrder}
          order={order}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          onClick={handleClickSimulation}
          onSelectSimulations={handleSelectedSimulations}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          allSelected={allSelected}
        />
      </div>
      <SimpleModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={handleConfirmModal}
        title={terms.History.confirmDeletion(allSelected ? simulationsList.count : selectedSimulations.length)}
        confirmBtn="Supprimer"
      />
    </div>
  )
}
