import terms from 'assets/localize/terms'
import CrashModal from 'components/modals/crashModal/CrashModal'
import { modalSignal } from 'components/modals/wrapper'
import MAIN_API from 'config/config'
import { speedSignal } from 'features/logs/components/timeController/TimeController'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  ReactElement, useEffect, useRef,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { setSnackbar, SnackbarSeverity } from 'reducers/app'
import {
  setAltBeep, setForm, setTcoBeep, updateStream,
} from 'reducers/interfaces/slices'
import {
  clearLogs,
  closeWebSocket,
  setCrashMessage,
  setFinalTime,
  setPause, setWebSocket, updateMessages,
} from 'reducers/simulations/slices'
import {
  closeCommandWebSocket,
  closeInstallationWebSocket,
  closeTrainWebSocket, setBeep,
  setCommandItems,
  setCommandWebSocket,
  setDriverCommunication, setFormError,
  setInstallationDetails,
  setInstallationWebSocket,
  setManeuver, setMission, setShouldCloseManagerTab, setShouldCloseManeuver,
  setTrainDetails,
  setTrains, setTrainWebSocket, updateSnciStream,
} from 'reducers/snci/slices'
import {
  setPause as setDashboardPause,
  stopSimulation, updateStudents,
} from 'reducers/trainer/slices'
import {
  isInterfaceWindow,
  isLogsWindow, isSnciWindow, isTcoWindow, isTptWindow,
  isTrainerWindow,
} from 'services/route'
import {
  CommandSocketMessage, InstallationSocketMessage, SocketMessage, TrainManagerSocketMessage,
} from 'types/websocket'
import { isDevEnv } from 'utils'

type Props = {
  type: string;
}

// todo refactor avoid calling store actions to have multiple ws on same page + everything actually, goto hook
export default function SimulationStream({ type }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const { selectedSimulation, webSocket } = useAppSelector(state => state.simulations)
  const { dashboard } = useAppSelector(state => state.trainer)
  const [queryParams] = useSearchParams()
  const simulationWasStopped = useRef(false)
  const init = useRef(false)
  const socket = useRef(null)

  const getCloseFct = () => {
    if (type === 'train-manager') return closeWebSocket
    if (type === 'installation-manager') return closeInstallationWebSocket
    if (type === 'ancillary-command-manager') return closeCommandWebSocket

    return closeTrainWebSocket
  }

  const getSetFct = () => {
    if (type === 'train-manager') return setTrainWebSocket
    if (type === 'installation-manager') return setInstallationWebSocket
    if (type === 'ancillary-command-manager') return setCommandWebSocket

    return setWebSocket
  }

  const closeWS = getCloseFct()
  const setWS = getSetFct()

  const defaultSimulation = type === 'dashboard' ? dashboard : selectedSimulation

  const closeSocket = () => {
    if (!isLogsWindow() && !isTcoWindow() && !isTptWindow() && !isTrainerWindow() && !isSnciWindow()) {
      socket?.current?.close()
      dispatch(closeWS())
    }
  }

  useEffect(() => {
    if (defaultSimulation && queryParams.get('mode') !== 'history'
      && !window.location.href.includes('static') && !init.current) {
      init.current = true
      const wsConnect = () => {
        const ws = new WebSocket(
          `${MAIN_API.websocket}/masters-ws/stream/${defaultSimulation?.id}/${type}/?_auth_token=${
            `${localStorage.getItem('access_token')}`}`,
        )

        ws.onmessage = (event: MessageEvent) => {
          let responseBatch = JSON.parse(event.data as string)

          // if there's too many messages in one batch,
          // react doesn't seem to update fast enough between redux and useeffect
          if (responseBatch.length > 1) {
            const map = responseBatch.reduce((prev, curr) => {
              if (curr.messageType !== SocketMessage.svg) return prev
              prev = { ...prev, ...curr.patch }
              return prev
            }, {})

            // todo refacto
            if (isSnciWindow()) {
              if (type !== 'train-manager') {
                dispatch(updateSnciStream(map))
              } else {
                dispatch(setDriverCommunication(map))
              }
            } else {
              dispatch(updateStream(map))
            }
            responseBatch = responseBatch.filter(msg => msg.messageType !== SocketMessage.svg)
          }
          responseBatch.forEach(response => {
            if (response.messageType === SocketMessage.svg) {
              if (isSnciWindow()) {
                dispatch(updateSnciStream(response.patch))
                return
              }
              dispatch(updateStream(response.patch))
            }

            if (response.messageType === SocketMessage.crash) {
              dispatch(setCrashMessage(response.errorMessage))
              modalSignal.value = <CrashModal />
            }

            if (response.messageType === SocketMessage.pause) {
              dispatch(setPause(response.pause))
            }

            if (response.messageType === SocketMessage.stop) {
              if (('simulationId' in response) && isDevEnv()) {
                dispatch(stopSimulation(response.simulationId))
                return
              }
              simulationWasStopped.current = true
              webSocket?.current?.close()
              dispatch(closeWS())
            }

            if (response.messageType === SocketMessage.speed) {
              speedSignal.value = response.speed
            }

            if (response.messageType === SocketMessage.clearLogs) {
              dispatch(clearLogs())
            }

            if (response.messageType === SocketMessage.shortBeep || response.messageType === SocketMessage.longBeep) {
              dispatch(setBeep(response))
            }
            if (response.messageType === SocketMessage.transitCancellationBeep) {
              dispatch(setAltBeep(response))
            }

            if (response.messageType === SocketMessage.discordanceBeep) {
              dispatch(setTcoBeep(response))
            }

            if (response.messageType === TrainManagerSocketMessage.trainList) {
              dispatch(setTrains(response.trains))
            }

            if (response.messageType === TrainManagerSocketMessage.trainList) {
              dispatch(setTrains(response.trains))
            }

            if (response.messageType === TrainManagerSocketMessage.pkError
              || response.messageType === TrainManagerSocketMessage.indexError) {
              dispatch(setFormError(response))
            }

            if (response.messageType === TrainManagerSocketMessage.driverCommunication) {
              dispatch(setDriverCommunication(response))
            }

            if (response.messageType === TrainManagerSocketMessage.maneuverDescription) {
              dispatch(setManeuver(response))
            }

            if (response.messageType === TrainManagerSocketMessage.trainDynamicDescription
              || response.messageType === TrainManagerSocketMessage.trainStaticDescription) {
              const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                messageType, timestamp, consumerId, ...trainInfo
              } = response
              dispatch(setTrainDetails(trainInfo))
            }

            if (response.messageType === TrainManagerSocketMessage.missionDescription) {
              dispatch(setMission(response))
            }

            if (response.messageType === TrainManagerSocketMessage.closeManeuverMode) {
              dispatch(setShouldCloseManeuver(true))
            }
            if (response.messageType === TrainManagerSocketMessage.closeManagerTab) {
              dispatch(setShouldCloseManagerTab(true))
            }

            if (response.messageType === InstallationSocketMessage.installationDescription) {
              dispatch(setInstallationDetails(response))
            }

            if (response.messageType === CommandSocketMessage.commandDescription) {
              dispatch(setCommandItems(response.descriptionItems))
            }

            if (isDevEnv()) {
              if (isTrainerWindow() && 'simulationId' in response) {
                dispatch(updateStudents(response))
                return
              }
              if (response.messageType === SocketMessage.forcePause) {
                dispatch(setDashboardPause(response.pause))
              }
              if (response.messageType === SocketMessage.finalRecordedTime) {
                dispatch(setFinalTime(response.time))
              }

              if (response.messageType === SocketMessage.clickabilityForm && isInterfaceWindow()) {
                dispatch(setForm(response.form))
              }
            }

            dispatch(updateMessages(response))
          })
        }

        ws.onclose = () => {
          // todo refacto
          if (((isTcoWindow() || isTptWindow()) && !window.location.href.includes('static'))
            || isLogsWindow() || isSnciWindow() || isTrainerWindow()) {
            dispatch(setSnackbar({ message: terms.Error.webSocket.lostConnection, severity: SnackbarSeverity.WARNING }))
            if (!simulationWasStopped.current) {
              setTimeout(() => {
                const newSocket = wsConnect()
                socket.current = newSocket
                dispatch(setWS({ current: newSocket }))
              }, 1000)
            }
          }
        }
        return ws
      }

      const wsCurrent = wsConnect()
      socket.current = wsCurrent

      dispatch(setWS({ current: wsCurrent }))

      // to remove ?
      return () => {
        closeSocket()
      }
    }

    return () => {
      closeSocket()
    }
  }, [defaultSimulation])

  return (
    <> </>
  )
}
