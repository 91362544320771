import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isLongBeep, isShortBeep, ManeuverButtonState } from 'features/snci/services/snci'
import { MutableRefObject } from 'react'
import {
  LongBeep, ShortBeep, SimulationsState, SvgStream,
  TstTable,
} from 'reducers/types'
import {
  DriverCommunication,
  FormError,
  Installation,
  Maneuver,
  Mission,
  SightState, Train, TrainDetails,
  TransitCancellationCounter,
} from 'types/snci'

const EMPTY_TRAIN: TrainDetails = {
  trainIndex: '',
  trainId: 0,
  onSightState: SightState.notOnSight,
  pk: '',
  speed: 0,
  direction: '',
  composition: '',
  compositionDetail: '',
  destination: '',
  observation: '',
  rollingStock: '',
  traction: '',
  length: 0,
  maneuverButtonState: ManeuverButtonState.unavailable,
  missionChangeVisibility: false,
  twinningVisibility: false,
  untwinningVisibility: false,
  driverCommunicationVisibility: false,
  untwin: false,
  untwinDetail: '',
  twin: false,
}

export type SnciState = {
  isOpen: boolean;
  stream: SvgStream;
  simulationState: SimulationsState;
  html: string;
  beep: ShortBeep | LongBeep;
  tstTable: TstTable;
  tstTableData: ArrayBuffer | undefined;
  trainWebsocket: MutableRefObject<WebSocket>;
  trains: Train[];
  trainDetails: TrainDetails;
  formError: FormError;
  driverCommunication: DriverCommunication;
  maneuver: Maneuver;
  mission: Mission;
  shouldCloseManeuver: boolean;
  shouldCloseManagerTab: boolean;
  installationDetails: Installation; // todo
  installationWebSocket: MutableRefObject<WebSocket>;
  commandWebSocket: MutableRefObject<WebSocket>;
  commandItems: TransitCancellationCounter[]
}

const initialState: SnciState = {
  isOpen: false,
  stream: undefined,
  simulationState: SimulationsState.stopped,
  html: '',
  beep: undefined,
  tstTable: undefined,
  tstTableData: undefined,
  trainWebsocket: undefined,
  trains: [],
  trainDetails: EMPTY_TRAIN,
  formError: undefined,
  driverCommunication: undefined,
  maneuver: undefined,
  mission: undefined,
  shouldCloseManeuver: false,
  shouldCloseManagerTab: false,
  installationDetails: undefined, // todo
  installationWebSocket: undefined,
  commandWebSocket: undefined,
  commandItems: [],
}

const snciSlice = createSlice({
  name: 'snci',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },
    setHtml: (state, action: PayloadAction<string>) => {
      state.html = action.payload
    },
    setTstTable: (state, action: PayloadAction<TstTable>) => {
      state.tstTable = action.payload
    },
    setTstTableData: (state, action: PayloadAction<ArrayBuffer | undefined>) => {
      state.tstTableData = action.payload
    },
    resetTstTableData: state => {
      state.tstTableData = null
    },
    setBeep: (state, action: PayloadAction<ShortBeep | LongBeep>) => {
      if (!state.beep || isShortBeep(state.beep)) {
        state.beep = action.payload
        return
      }

      if (isLongBeep(state.beep)) {
        if (!state.beep.state || isLongBeep(action.payload)) {
          state.beep = action.payload
        }
      }
    },
    setWebSocket: (state, action: PayloadAction<MutableRefObject<WebSocket>>) => {
      state.trainWebsocket = action.payload
    },
    closeWebSocket: state => {
      if (state.trainWebsocket?.current) {
        state.trainWebsocket?.current.close()
        state.trainWebsocket = undefined
      }
    },
    setTrains: (state, action: PayloadAction<Train[]>) => {
      state.trains = action.payload
    },
    setTrainDetails: (state, action: PayloadAction<Partial<TrainDetails>>) => {
      state.trainDetails = { ...state.trainDetails, ...action.payload }
    },
    resetTrainDetails: state => {
      state.trainDetails = EMPTY_TRAIN
    },
    setFormError: (state, action: PayloadAction<typeof state.formError>) => {
      state.formError = action.payload
    },
    setDriverCommunication: (state, action: PayloadAction<DriverCommunication>) => {
      state.driverCommunication = action.payload
    },
    setManeuver: (state, action: PayloadAction<Maneuver>) => {
      state.maneuver = action.payload
    },
    setMission: (state, action: PayloadAction<Mission>) => {
      state.mission = action.payload
    },
    setShouldCloseManeuver: (state, action: PayloadAction<boolean>) => {
      state.shouldCloseManeuver = action.payload
    },
    setShouldCloseManagerTab: (state, action: PayloadAction<boolean>) => {
      state.shouldCloseManagerTab = action.payload
    },
    setInstallationWebSocket: (state, action: PayloadAction<MutableRefObject<WebSocket>>) => {
      state.installationWebSocket = action.payload
    },
    closeInstallationWebSocket: state => {
      if (state.installationWebSocket?.current) {
        state.installationWebSocket?.current.close()
        state.installationWebSocket = undefined
      }
    },
    setInstallationDetails: (state, action: PayloadAction<Installation>) => {
      state.installationDetails = action.payload
    },
    setCommandWebSocket: (state, action: PayloadAction<MutableRefObject<WebSocket>>) => {
      state.commandWebSocket = action.payload
    },
    closeCommandWebSocket: state => {
      if (state.commandWebSocket?.current) {
        state.commandWebSocket?.current.close()
        state.commandWebSocket = undefined
      }
    },
    setCommandItems: (state, action: PayloadAction<TransitCancellationCounter[]>) => {
      state.commandItems = action.payload
    },
  },
})

export const {
  setOpen: setSnciOpen,
  updateStream: updateSnciStream,
  setHtml: setSnciHtml,
  setBeep,
  setTstTable,
  setTstTableData,
  resetTstTableData,
  setWebSocket: setTrainWebSocket,
  closeWebSocket: closeTrainWebSocket,
  setTrains,
  setTrainDetails,
  resetTrainDetails,
  setFormError,
  setDriverCommunication,
  setManeuver,
  setMission,
  setShouldCloseManeuver,
  setShouldCloseManagerTab,
  setInstallationWebSocket,
  closeInstallationWebSocket,
  setInstallationDetails,
  setCommandWebSocket,
  closeCommandWebSocket,
  setCommandItems,
} = snciSlice.actions

export default snciSlice.reducer
